<template lang="pug">
	.main-wrapper.checkup-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary MedClub / <b>Agendamentos de Checkup</b>

		Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
			.p-grid.p-fluid.p-align-end
				.p-col-12.p-md-3
					label.form-label Estabelecimento:
					Dropdown( v-model='filters.cd_estabelecimento' :options='options.estabelecimentos' :filter='options.estabelecimentos.length > 0'
						dataKey='id' optionLabel='nm_fantasia' optionValue='id' placeholder='TODOS' @change='applyFilters()' )

				.p-col-12.p-md-3
					label.form-label Tipo de checkup:
					Dropdown( v-model='filters.cd_tipo_checkup' :options='options.tipo_checkup' :filter='options.tipo_checkup.length > 0'
						dataKey='id' optionLabel='ds_checkup' optionValue='id' placeholder='TODOS' @change='applyFilters()' )

				.p-col-12.p-md-6
					label.form-label Paciente:
					.p-inputgroup
						InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_paciente' @keyup='checkEmptyField("nm_paciente")' )
						Button(icon='jam jam-search' @click='applyFilters()')

				.p-col-6.p-md-3
					label.form-label Data inicial:
					.p-inputgroup
						Calendar( v-model='filters.dt_inicio' dateFormat="dd/mm/yy" :locale="ptbr" :maxDate='filters.dt_fim'
							:manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
						Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_inicio = null; applyFilters()')

				.p-col-6.p-md-3
					label.form-label Data final:
					.p-inputgroup
						Calendar( v-model='filters.dt_fim' dateFormat="dd/mm/yy" :locale="ptbr" :minDate='filters.dt_inicio'
							:manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
						Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_fim = null; applyFilters()')

		ProgressBar(v-if='waiting' mode="indeterminate")
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
		div(v-else)
			Panel.datatable(header='Lista de checkups')

				Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

				DataTable(:value="list")
					Column(headerStyle='width: 4em' field='id' bodyStyle='text-align:center')
					Column(header='Data' field='dt_criado_f' headerStyle='width: 10%')
					Column(header='Paciente' field='nm_pessoa_fisica' headerStyle='width: 30%')
					Column(headerStyle='width: 18%;' field='nr_telefone_f' header='Telefone')
						template(#body='props')
							p.ta-center(style='margin:0')
								span(style='display:inline-block;vertical-align:middle') {{ props.data.nr_telefone_f }}
								a.whatsapp-link(v-if='props.data.nr_telefone_f' :href="`https://api.whatsapp.com/send?phone=${ props.data.nr_telefone_ws }`" target='_blank')
									i(class='jam jam-whatsapp')
					Column(header='Estabelecimento' field='nm_fantasia' headerStyle='width: 20%')
					Column(header='Tipo de Checkup' field='ds_checkup' headerStyle='width: 20%')
					Column(header='Ações' headerStyle='width: 8%')
						template(#body='props')
							.ta-center
								Button.p-button-raised.p-button-rounded.mx-1(
									v-tooltip.top="'Ver Compra'"
									icon="jam jam-unordered-list"
									@click="$router.push(`/transacoes-financeiras/visualizar/${ props.data.id }/`)")
				
				Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
	.checkup-listar {
		.waitingStatus {
			width: 25px;
			height: auto;
		}

		.whatsapp-link {
            margin-left: 8px;
            background-color: #0da64d;
            color: #fff;
            font-weight: 700;
            border-radius: 50%;
            padding: 4px;
            display: inline-block;
            vertical-align: middle;
            width: 28px;
            height: 28px;
            line-height: 24px;
            text-align: center;
            transition: 0.25s;
            &:hover {
                background-color: #0a7d3a;
            }
        }
	}
</style>

<script>
	import ProgressBar from 'primevue/progressbar'
	import Button from 'primevue/button'
	import Panel from 'primevue/panel'
	import Paginator from 'primevue/paginator'
	import DataTable from 'primevue/datatable'
	import Dialog from 'primevue/dialog'
	import Column from 'primevue/column'
	import Tooltip from 'primevue/tooltip'
	import ProgressSpinner from 'primevue/progressspinner'
	import InputText from 'primevue/inputtext'
	import Dropdown from 'primevue/dropdown'
	import Calendar from 'primevue/calendar'
	import moment from 'moment'

	import wsConfigs from './../../middleware/configs'
	import { pCalendarLocale_ptbr, fixTelefoneFormat } from './../../utils'
	import { Checkup, Estabelecimento } from '../../middleware'
	export default {
		components: { ProgressBar, Button, Panel, Paginator, DataTable, Column,
			Dropdown, Tooltip, Dialog, ProgressSpinner, InputText, Calendar },
		directives: { tooltip: Tooltip },
		created () {
			this.waiting = true
			Checkup.findAllTipoCheckup().then(response => {
				this.options.tipo_checkup = [ { id: null, ds_checkup: 'TODOS' } ]
				if (response.status === 200) this.options.tipo_checkup = this.options.tipo_checkup.concat(response.data)
				Estabelecimento.findAll().then(response => {
					this.options.estabelecimentos = [ { id: null, nm_fantasia: 'TODOS' } ]
					if (response.status === 200) this.options.estabelecimentos = this.options.estabelecimentos.concat(response.data)
					this.applyFilters()
				})
			})
		},
		data () {
			return {
				list: [],
				ptbr: pCalendarLocale_ptbr,
				windowInnerWidth: window.innerWidth,
				waiting: false,
				filters: {
					cd_estabelecimento: null,
					nm_paciente: null,
					cd_tipo_checkup: null,
					dt_inicio: moment()._d,
					dt_fim: moment()._d
				},
				paginator: {
					page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				},
				options: { estabelecimentos: [], tipo_checkup: [] },
			}
		},
		methods: {
			getList (params) {
				this.waiting = true
				return Checkup.findAllCompras(params).then(response => {
					if (response.status == 200) {
						// this.paginator.count = response.data.count

						response.data.forEach(entry => {
							entry.dt_criado_f = moment(entry.dt_criado).format('DD/MM/YYYY')
							if (entry.nr_telefone) {
								entry.nr_telefone_f = fixTelefoneFormat(entry.nr_telefone)
								entry.nr_telefone_ws = entry.nr_telefone.match(/\d+/g).join('')
								if (entry.nr_telefone_ws.substr(0, 2) === '55') {
									entry.nr_telefone_ws = `${ entry.nr_telefone_ws }`
								} else {
									entry.nr_telefone_ws = `55${ entry.nr_telefone_ws }`
								}
							}
						})

						this.list = response.data
					}
					this.waiting = false
					return true
				})
			},
			applyFilters () {
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
				Object.keys(this.filters).forEach((key) => {
					if (key === 'nr_cpf') {
						params.nr_cpf = this.filters[key].replace(/(\.|-|_)/g, '')
					} else if (key === 'nf') {
						if (this.filters[key] !== null) params.nf = this.filters[key] 
					} else if (key.substr(0, 3) == 'dt_') {
						if (this.filters[key]) params[key] = moment(this.filters[key]).format('YYYY-MM-DD')
					} else if (this.filters[key]) params[key] = this.filters[key]
				})
				this.getList(params)
			},
			onPage (ev) {
				this.paginator.page = ev.page + 1
				this.applyFilters()
			},
			checkEmptyField (field) {
				if (! this.filters[field].length) this.applyFilters()
			},
		}
	}
</script>